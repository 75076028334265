import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * This timer will receive as input:
 *
 * 1. The output format e.g hr:min:secs or even min:secs
 * 2. The time to countdown in milliseconds
 *
 * Then it will return a formatted output based on the received input.
 */
@Pipe({
  name: 'countdownTimerPipe',
  standalone: true,
})
export class CountdownTimerPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}

  transform(value: number, format: string): string {
    switch (format) {
      case 'day:hr:min:secs':
        return this._millisecondsToDaysHoursMinsSeconds(value);
      case 'hr:min:secs':
        return 'hr:min:secs';
      case 'min:secs':
        return this._millisecondsToMinsAndSecondsFormat(value);
      case 'secs':
        return 'secs';
      case 'named-day:hr:min:secs':
        return this._millisecondsToDaysHoursMinsSecondsNamed(value);
      default:
        return value.toString();
    }
  }

  private _millisecondsToDaysHoursMinsSeconds(value: number): string {
    const { days, hours, minutes, seconds } = this._daysHoursMinsSecondsFromMilliseconds(value);
    return `${days}:${hours}:${minutes}:${seconds}`;
  }

  private _millisecondsToDaysHoursMinsSecondsNamed(value: number): string {
    const { days, hours, minutes, seconds } = this._daysHoursMinsSecondsFromMilliseconds(value);
    const dayLabel = this._translateService.instant('DATE_FORMAT.DAY');
    const hourLabel = this._translateService.instant('DATE_FORMAT.HOUR');
    const minuteLabel = this._translateService.instant('DATE_FORMAT.MINUTE');
    const secondLabel = this._translateService.instant('DATE_FORMAT.SECONDS');
    return `${days} ${dayLabel} : ${hours} ${hourLabel} : ${minutes} ${minuteLabel} : ${seconds} ${secondLabel}`;
  }

  private _daysHoursMinsSecondsFromMilliseconds(value: number): {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  } {
    const days = this._commonOptionallyPrependZero(Math.floor(value / (1000 * 60 * 60 * 24)));
    const hours = this._commonOptionallyPrependZero(
      Math.floor((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    );
    const minutes = this._commonOptionallyPrependZero(
      Math.floor((value % (1000 * 60 * 60)) / (1000 * 60)),
    );
    const seconds = this._commonOptionallyPrependZero(Math.floor((value % (1000 * 60)) / 1000));
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  private _millisecondsToMinsAndSecondsFormat(value: number): string {
    const timeInMinutes = Math.floor(value / (1000 * 60));
    const timeInSeconds = Math.floor((value % (1000 * 60)) / 1000);

    return `${this._commonOptionallyPrependZero(timeInMinutes)}:${this._commonOptionallyPrependZero(
      timeInSeconds,
    )}`;
  }

  private _commonOptionallyPrependZero(value: number): string {
    const valueToString = value.toString();
    return value < 10 ? `0${valueToString}` : valueToString;
  }
}
